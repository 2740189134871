import iconCircle from './kwa.png'
import illOpen from './illustr-open.webp'
import illFlexible from './illustr-flexible.webp'
import illTrust from './illustr-trustworthy.webp'

import logoIkeikaku from './logo-ikeikaku.webp'
import logoCtc from './logo-ctc.webp'
import logoBefound from './logo-befound.webp'
import logoLc from './logo-lc.webp'
import logoOutdaterx from './logo-outdaterx.webp'
import logoFreshbowl from './logo-freshbowl.svg'
import logoTrenzyton from './logo-trenzyton.webp'
import logoHandy from './logo-handy.webp'

import demoFreshbowl from './freshbowls.webp'
import demoHandy from './handy_jr.webp'

import noCookie from './no-cookie.webp'
import thoughtCloud from './thought-cloud.webp'

import cpNodejs from './nodejs.svg'
import cpElectron from './electron.webp'
import cpRust from './rust.webp'
import cpAws from './aws.webp'
import cpAzure from './azure.webp'
import cpGcloud from './gcloud.webp'
import cpPython from './python.webp'
import cpServerless from './serverless.webp'
import cpReact from './react.webp'
import cpAngular from './angular.webp'

export {
  iconCircle,
  illOpen,
  illFlexible,
  illTrust,

  logoIkeikaku,
  logoCtc,
  logoBefound,
  logoLc,
  logoOutdaterx,
  logoFreshbowl,
  logoTrenzyton,
  logoHandy,

  demoFreshbowl,
  demoHandy,

  noCookie,
  thoughtCloud,

  cpNodejs,
  cpElectron,
  cpRust,
  cpAws,
  cpAzure,
  cpGcloud,
  cpPython,
  cpServerless,
  cpReact,
  cpAngular,
}